<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                작성글
              </h4>
              <p class="card-title-desc">현재 작성된 강의 글을 확인합니다.</p>
              <div class="mb-3">
                <router-link to="/post/add">
                  <b-button variant="primary" class="float-right">등록</b-button>
                </router-link>
              </div>
              <div class=" mg-b-10 fr">
                <form class="d-flex" @submit.prevent="getQnaListSearch">
                  <select v-model="courseId" class="form-control mg-r-5">
                    <option value="">코스선택</option>
                    <option v-for="item in courseList" :key="item.idx" :value="item.idx" selected>
                      {{ item.name }}
                    </option>
                  </select>
                  <select v-model="sectionId" class="form-select mg-r-5">
                    <option value="">섹션선택</option>
                    <option v-for="item in sectionData" :key="item.idx" :value="item.idx">
                      {{ item.name }}
                    </option>
                  </select>
                  <b-form-input
                    id="inline-form-input-name"
                    v-model="searchKeyword"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="제목"
                  ></b-form-input>
                  <button class="btn btn-primary btn-sm" type="submit">
                    검색
                  </button>
                </form>
              </div>
              <div class="table-responsive mb-0">
                <table class="table table-hover">
                  <colgroup></colgroup>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>코스</th>
                      <!-- <th>강의</th> -->
                      <th>질문</th>
                      <th>질문자</th>
                      <th>답변수</th>
                      <th>질문일자</th>
                      <th>공개여부</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in list" :key="item.idx">
                      <td>{{ pageData.pageStartIndex - i }}</td>
                      <td>{{ item.courseName }}</td>
                      <!-- <td>{{ item.sectionName }}</td> -->
                      <!-- <td>{{ item.pTitle }}</td> -->
                      <td>{{ item.title }}</td>
                      <td>{{ item.mbName }}<br />({{ item.mbId }})</td>
                      <td>{{ item.replyCnt }}</td>
                      <td>{{ item.regdate | moment('YY-MM-DD HH:MM') }}</td>
                      <td>
                        <span v-if="item.openYn === 'Y'" class="badge bg-primary">공개</span>
                        <span v-else class="badge bg-danger">바공개</span>
                      </td>
                      <td>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="me-1"
                          @click="$router.push(`/qna/modify/${item.idx}`)"
                          >답변</b-button
                        >
                        <b-button
                          :variant="item.openYn === 'Y' ? 'danger' : 'primary'"
                          size="sm"
                          @click="openChange(item.idx, item.openYn)"
                          >{{ item.openYn === 'Y' ? '비공개' : '공개' }}</b-button
                        >
                      </td>
                    </tr>
                    <tr v-if="list.length === 0">
                      <td colspan="8">데이터가 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex-jac">
                <b-pagination
                  v-model="pageData.page"
                  :total-rows="pageData.totalCnt"
                  :per-page="pageData.perPage"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Course',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Qna List',
      items: [
        {
          text: 'Admin',
          href: '/'
        },
        {
          text: 'Qna',
          href: '/'
        },
        {
          text: 'Qna List',
          active: true
        }
      ],
      courseId: '',
      sectionId: '',
      searchKeyword: '',
      courseList: [],
      sectionData: [],
      list: [],
      pageData: {
        page: 1, // 현재 페이지
        perPage: 15, // 페이지 초기 갯수
        totalCnt: 10000, // 전체 갯수
        pageStartIndex: 0 // 페이지 시작 번호
      }
    };
  },
  async created() {
    this.courseId = this.$store.state.qna.courseId;
    this.sectionId = this.$store.state.qna.sectionId;
    this.searchKeyword = this.$store.state.qna.searchKeyword;

    if (this.$store.state.qna.page !== 1) this.pageData.page = this.$store.state.qna.page;
    if (this.$store.state.qna.courseId !== null) this.courseId = this.$store.state.qna.courseId;
    if (this.$store.state.qna.sectionId !== null) this.sectionId = this.$store.state.qna.sectionId;

    await this.initCourseList();
    await this.getQnaList();
  },
  watch: {
    async courseId() {
      await this.$store
        .dispatch('section/getSectionList', {
          courseIdx: this.courseId
        })
        .then(res => {
          this.sectionData = res.list;
        });

      this.$store.commit('qna/SET_COURSE_ID', this.courseId);
    },
    async sectionId() {
      this.$store.commit('qna/SET_SECTION_ID', this.sectionId);
    }
  },
  methods: {
    pageClick(button, page) {
      this.pageData.page = page;
      this.$store.commit('qna/SET_PAGE', this.pageData.page);
      this.getQnaList();
    },
    async initCourseList() {
      await this.$store
        .dispatch('course/getCourseList', {
          page: this.pageData.page,
          perPage: this.pageData.perPage,
          order: 'asc'
        })
        .then(res => {
          this.courseList = res.data.list;
        });
    },
    async getQnaList() {
      await this.$store
        .dispatch('qna/getQnaList', {
          page: this.pageData.page ? this.pageData.page : 1,
          limit: this.pageData.perPage,
          courseId: this.courseId,
          sectionId: this.sectionId,
          serachKeyword: this.searchKeyword,
          orderKey: 'p_order_num',
          orderAction: 'desc'
        })
        .then(({data}) => {
          console.log(data);
          this.list = data.list;
          this.pageData.totalCnt = data.totalCnt;
          this.pageData.pageStartIndex =
            this.pageData.totalCnt - (this.pageData.page - 1) * this.pageData.perPage;
          console.log(this.pageData.page);
        });
    },
    getQnaListSearch() {
      this.$store.commit('qna/SET_SEARCH_KEYWORD', this.searchKeyword);
      this.pageData.page = 1;
      this.getQnaList();
    },
    async openChange(idx, openYn) {
      const openStr = openYn === 'Y' ? '비공개' : '공개';
      this.$confirm({
        message: `질문을 ${openYn === 'Y' ? '비공개' : '공개'} 처리하시겠습니까?`,
        button: {
          no: '취소',
          yes: '확인'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            await this.$store
              .dispatch('qna/qnaOpenChange', {
                qnaId: idx,
                openYn: openYn === 'Y' ? 'N' : 'Y'
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? `${openStr} 처리 되었습니다.` : 'Error',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });

                if (res.result) {
                  this.getQnaList();
                }
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}
form input,
form select {
  min-width: 80px;
  margin-right: 10px;
}
form button {
  min-width: 50px;
}
</style>
